import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-cad',
  templateUrl: './home-cad.component.html',
  styleUrls: ['./home-cad.component.scss']
})
export class HomeCadComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
