import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import baseUrl from './baseUrl';
import { HttpClient as HttpService } from 'src/app/shared/services/http.service';

import { User } from '../models';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient, private httpService: HttpService, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  loginSocial(accessToken: string) {
    localStorage.removeItem('permissoes');

    const revoke = window.localStorage.getItem('revoke');

    return this.http
      .post<any>(baseUrl.url + 'loginsocial', { accessToken, revoke })
      .pipe(
        map(user => {
          debugger
          if (user && user.token) {

            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('avatar', user.user.avatar);
            localStorage.setItem('moldura', user.user.moldura);
            localStorage.setItem('objetivo', JSON.stringify(user.user.objetivo));
            localStorage.setItem('funcoes_ativas', JSON.stringify(user.user.funcoes_ativas));
            localStorage.setItem('incentivo', 'login');
            localStorage.removeItem('revoke');

            // Validação para exibição de ações experimente
            if (user.user.primeiro_login) {
              localStorage.setItem('acao_experimente', '1');
            }

            if (user.user.funcoes_ativas.experimente) {
              localStorage.setItem('acao_experimente_cupom', '1');
            }

            const aluno = user.user;
            const url = `notificacoes-alunos/nao-lidas/${aluno.id}`;
            this.httpService.get(encodeURI(url)).subscribe((resp: any) => {
              localStorage.setItem('avisos', resp.notificacoes);
            });
            this.currentUserSubject.next(user);
          }
          return user;
        }),
        catchError(err => {
          console.log(err);
          return err;
        })
      );
  }

  login(email: string, password: string) {
    localStorage.removeItem('permissoes');

    const revoke = window.localStorage.getItem('revoke');

    return this.http
      .post<any>(baseUrl.url + 'login', { email, password, revoke })
      .pipe(
        map(user => {

          if (user && user.token) {
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('avatar', user.user.avatar);
            localStorage.setItem('moldura', user.user.moldura);
            localStorage.setItem('objetivo', JSON.stringify(user.user.objetivo));
            localStorage.setItem('funcoes_ativas', JSON.stringify(user.user.funcoes_ativas));
            localStorage.setItem('incentivo', 'login');
            localStorage.removeItem('revoke');

            // Validação para exibição de ações experimente
            if (user.user.primeiro_login) {
              localStorage.setItem('acao_experimente', '1');
            }

            if (user.user.funcoes_ativas.experimente) {
              localStorage.setItem('acao_experimente_cupom', '1');
            }

            const aluno = user.user;
            const url = `notificacoes-alunos/nao-lidas/${aluno.id}`;
            this.httpService.get(encodeURI(url)).subscribe((resp: any) => {
              localStorage.setItem('avisos', resp.notificacoes);
            });

            this.currentUserSubject.next(user);
          }
          return user;
        }),
        catchError(err => {
          return err;
        })
      );
  }

  loginByToken(token: string) {
    localStorage.removeItem('permissoes');

    return this.http
      .post<any>(baseUrl.url + 'loginByToken', { token })
      .pipe(
        map(user => {

          if (user && user.token) {
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('avatar', user.user.avatar);
            localStorage.setItem('moldura', user.user.moldura);
            localStorage.setItem('objetivo', JSON.stringify(user.user.objetivo));
            localStorage.setItem('funcoes_ativas', JSON.stringify(user.user.funcoes_ativas));
            localStorage.setItem('incentivo', 'login');
            localStorage.removeItem('revoke');

            // Validação para exibição de ações experimente
            if (user.user.primeiro_login) {
              localStorage.setItem('acao_experimente', '1');
            }

            if (user.user.funcoes_ativas.experimente) {
              localStorage.setItem('acao_experimente_cupom', '1');
            }

            const aluno = user.user;
            const url = `notificacoes-alunos/nao-lidas/${aluno.id}`;
            this.httpService.get(encodeURI(url)).subscribe((resp: any) => {
              localStorage.setItem('avisos', resp.notificacoes);
            });

            this.currentUserSubject.next(user);
          }
          return user;
        }),
        catchError(err => {
          return err;
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.clear();
    this.currentUserSubject.next(null);
    this.router.navigate(['/login']);
  }
}
