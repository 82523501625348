import { Injectable } from '@angular/core';
import { HttpClient as Http, HttpHeaders } from '@angular/common/http';
import baseUrl from './baseUrl';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';

@Injectable(
    { providedIn: 'root' }
)

export class HttpClient {
    constructor(
        private http: Http,
        private router: Router
    ) {}

    createAuthorizationHeader(headers: HttpHeaders, method: string): HttpHeaders {
        const user = JSON.parse(window.localStorage.getItem('user'));
        const token = user ? user.token : null;
        if (token) {
            headers = headers.append('Authorization', `Bearer ${token}`);
        }
        return headers;
    }

    get(url: string) {
        this.validateToken();
        let params = new HttpHeaders();
        params = params.append('Content-Type', 'application/json');
        const headers: HttpHeaders = this.createAuthorizationHeader(params, 'GET');

        return this.http.get(baseUrl.url + url, { headers });
    }

    post(url: string, data: any) {
        let params = new HttpHeaders();
        params = params.append('Content-Type', 'application/json');
        const headers: HttpHeaders = this.createAuthorizationHeader(params, 'POST');
        return this.http.post(baseUrl.url + url, data, {headers});
    }

    upload(url: string, data: FormData, update: Boolean = false) {
        const params = new HttpHeaders();
        // params = params.append('Content-Type', null);
        const method = !update ? 'POST' : 'PUT';
        const headers: HttpHeaders = this.createAuthorizationHeader(params, method);
        data.append('_method', method);
        return this.http.post(baseUrl.url + url, data, {headers});
    }

    put(url: string, data: any) {
        let params = new HttpHeaders();
        params = params.append('Content-Type', 'application/json');
        const headers: HttpHeaders = this.createAuthorizationHeader(params, 'PUT');
        return this.http.put(baseUrl.url + url, data, {headers});
    }

    delete(url: string) {
        let params = new HttpHeaders();
        params = params.append('Content-Type', 'application/json');
        const headers: HttpHeaders = this.createAuthorizationHeader(params, 'DELETE');
        return this.http.delete(baseUrl.url + url, {headers});
    }

    validateToken() {
        try {
            const helper = new JwtHelperService();
            let data: any = [];

            data = localStorage.getItem('user');
            data = JSON.parse(data);
            if (data) {

                const isExpired = helper.isTokenExpired(data.token);
                const decodedToken = helper.decodeToken(data.token);
                const expirationDate = helper.getTokenExpirationDate(data.token);
                if (isExpired) {
                    this.router.navigateByUrl('/login');
                }

                return true;
            }

            return false;
        } catch (err) {
            this.router.navigateByUrl('/login');
            console.log('err', err);
        }
    }

    activateToken() {
        try {
            const helper = new JwtHelperService();
            let data: any = [];

            data = localStorage.getItem('user');
            data = JSON.parse(data);
            if (data) {

                const isExpired = helper.isTokenExpired(data.token);
                const decodedToken = helper.decodeToken(data.token);
                const expirationDate = helper.getTokenExpirationDate(data.token);
                if (isExpired) {
                    localStorage.removeItem('user');
                    return false;
                }

                return true;
            }

            return false;
        } catch (err) {

            return false;
            // this.router.navigateByUrl('/login');
            // console.log('err', err);
        }
    }
}
