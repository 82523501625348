import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { MatSnackBarComponent } from '../../layout/components/mat-snack-bar/mat-snack-bar.component';

@Injectable()

export class Interceptor implements HttpInterceptor {
    constructor(private snackBar: MatSnackBarComponent,  private router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log(request);
        return next.handle(request).pipe(
            tap((event: HttpEvent<any>) => {
                // console.log(event);
                if (event instanceof HttpResponse) {
                    if (event.status === 200 || event.status === 201) {
                        if (event.body && event.body.mensagem && event.body.mensagem.length > 0) {
                            // tslint:disable-next-line:max-line-length
                            this.snackBar.openSnackBar(typeof event.body.mensagem === 'string' ? event.body.mensagem : event.body.mensagem[0], 'Fechar', 'default');
                        }
                    }
                    if (event.status === 400) {
                        if (event.body && event.body.mensagem && event.body.mensagem.length > 0) {
                            // tslint:disable-next-line:max-line-length
                            this.snackBar.openSnackBar(typeof event.body.mensagem === 'string' ? event.body.mensagem : event.body.mensagem[0], 'Fechar', 'default');
                        }
                    }
                    if (event.status === 403) {
                        if (event.body && event.body.mensagem && event.body.mensagem.length > 0) {
                            this.snackBar.openSnackBar('Acesso negado', 'Fechar', 'default');
                        }
                    }
                }
                return event;
            }),
            catchError(err => {
                // console.log(err);
                let mensagem = 'Algum problema ocorreu durante o processo. Por favor tente novamente.';
                if (err && err.error && err.error.mensagem && err.error.mensagem.length > 0) {
                  mensagem = typeof err.error.mensagem === 'string' ? err.error.mensagem : err.error.mensagem[0];
                }

                if (err && err.mensagem && err.mensagem.length > 0) {
                  mensagem = typeof err.mensagem === 'string' ? err.mensagem : err.mensagem[0];
                }

                if (err.status === 403) {
                  mensagem = 'Acesso negado';
                }

                if (err.status === 400) {

                }

                if (err.status === 401) {
                    localStorage.clear();
                    this.router.navigateByUrl('/login');
                    return throwError(null);
                }

                this.snackBar.openSnackBar(mensagem, 'Fechar', 'default');
                const error = err.error ? err.error.mensagem : err.statusText;
                return throwError(error);
            })
        );
    }
}
